import React from 'react';
import Modal from 'react-modal';
import injectSheet from 'react-jss';
import themeColors from '../../styles/theme';

import Translate from '../Translate';
import Button from '../button';
import colors from '../../styles/colors';

import runningPlansImage from '../../data/images/features/coach/running/running_plans.jpg';
import runningPlansImageMobile from '../../data/images/features/coach/running/running_plans_mobile.jpg';
import runCoachImage from '../../data/images/features/coach/running/garmin_run_coach.jpg';
import runCoachImageMobile from '../../data/images/features/coach/running/garmin_run_coach_mobile.jpg';
import exportCoachesImage from '../../data/images/features/coach/running/expert_coaches.jpg';
import exportCoachesImageMobile from '../../data/images/features/coach/running/expert_coaches_mobile.jpg';

import InfoSection from './infoSection';
import TwoColumnInfoSection from '../twoColumnInfoSection';
import { isChinaUser } from '../../config/isChina';

const styles = {
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    modal: {
        width: '800px',
        margin: '7% 0 0',
        marginLeft: 'calc(100vw - 72%)',
        background: '#fff',
        '& iframe': {
            margin: '0 0 10px 20px'
        }
    },
    modalHeader: {
        padding: '7px 15px',
        color: '#222',
        overflow: 'hidden',
        lineHeight: '1',
        display: 'flex'
    },
    modalCloseButton: {
        color: '#888',
        cursor: 'pointer',
        border: '0',
        opacity: '1',
        padding: '0',
        fontSize: '15px',
        background: 'transparent',
        fontWeight: '400',
        lineHeight: '11px',
        margin: '7px 0 0 auto'
    },
    buttonContainr: {
        marginTop: '25px'
    },
    link: {
        '& a': {
            color: colors.darkui_surface_1,
            textDecoration: 'underline'
        }
    },
    '@media screen and (max-width: 1200px)': {
        modal: {
            marginLeft: 'calc(100vw - 90%)'
        }
    },
    '@media screen and (max-width: 900px)': {
        modal: {
            marginLeft: '0px'
        }
    }
};

class RunningPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isChinaUser: false,
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            isChinaUser: isChinaUser()
        });
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // Gatsby build step with fail because window will be undefined, we need check first
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillMount() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        const { classes } = this.props;
        const { isMobile } = this.state;

        const button = isMobile ? (
            <Button size="large">
                <a href="https://youtu.be/ZwLVhdAoErg">{<Translate ns="prelogin_pages" content="watch_the_video" />}</a>
            </Button>
        ) : (
            <Button size="large" onClick={this.openModal}>
                {<Translate ns="prelogin_pages" content="watch_the_video" />}
            </Button>
        );

        return (
            <div className={classes.sectionContainer}>
                <InfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="running_plans" />}
                    contentList={[<Translate ns="prelogin_pages" content="running_plans_detail" />]}
                    desktopImage={runningPlansImage}
                    mobileImage={runningPlansImageMobile}
                    allowFullScreenImage={true}
                    textColor={themeColors.textColorWhite}
                >
                    <div>
                        {!this.state.isChinaUser && <div className={classes.buttonContainr}>{button}</div>}
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={this.closeModal}
                            contentLabel="Garmin Running Coach"
                            className={classes.modal}
                        >
                            <div className={classes.modalHeader}>
                                <button className={classes.modalCloseButton} onClick={this.closeModal}>
                                    x
                                </button>
                            </div>
                            <iframe
                                title="Youtube id ZwLVhdAoErg"
                                src="https://www.youtube.com/embed/ZwLVhdAoErg?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light"
                                frameBorder="0"
                                allowFullScreen
                                width="760"
                                height="428"
                            />
                        </Modal>
                    </div>
                </InfoSection>
                <TwoColumnInfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="run_coach" />}
                    contentList={[<Translate ns="prelogin_pages" content="run_coach_description" />]}
                    desktopImage={runCoachImage}
                    mobileImage={runCoachImageMobile}
                    backgroundColor={themeColors.white}
                    textColor={themeColors.textColor}
                    textAlign="right"
                    backgroundPosition="right"
                />
                <TwoColumnInfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="atp_title" />}
                    contentList={[
                        <span className={classes.link}>
                            <Translate
                                ns="prelogin_pages"
                                tag="div"
                                content="atp_content"
                                params={{
                                    0: 'http://www.jeffgalloway.com/',
                                    1: 'https://www.mcmillanrunning.com/',
                                    2: 'https://www.roadrunnerskc.com/'
                                }}
                            />
                        </span>
                    ]}
                    desktopImage={exportCoachesImage}
                    mobileImage={exportCoachesImageMobile}
                    backgroundColor={themeColors.white}
                    textColor={themeColors.textColor}
                    textAlign="left"
                    backgroundSize={'contain'}
                    backgroundRepeat={'no-repeat'}
                />
            </div>
        );
    }
}

export default injectSheet(styles)(RunningPanel);
