import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
    infoSection: {
        display: 'flex',
        paddingLeft: (props) => (props.allowFullScreenImage ? '0' : '8%'),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: (props) => (props.allowFullScreenImage ? `url(${props.desktopImage})` : ''),
        backgroundColor: (props) => (props.allowFullScreenImage ? '' : '#fff'),
        minHeight: '800px',
        borderBottom: '1px solid #eeeeee',
        '& > div:nth-of-type(1)': {
            flex: (props) => (props.allowFullScreenImage ? 'none' : '1 0 30%'),
            paddingTop: '18%',
            width: (props) => (props.allowFullScreenImage ? '28%' : 'unset'),
            marginLeft: (props) => (props.textAlign === 'right' ? '66%' : '12%')
        },
        '& > div:nth-of-type(2)': {
            flex: '1 0 60%',
            overflow: 'hidden'
        }
    },
    infoSectionTitle: {
        fontSize: '44px',
        lineHeight: '40px',
        fontWeight: 300,
        marginBottom: '20px',
        color: (props) => props.textColor
    },
    infoSectionContent: {
        color: (props) => props.textColor,
        fontSize: '18px',
        fontWeight: 300,
        '& > div:nth-of-type(2)': {
            marginBottom: '30px',
            fontSize: '18px',
            fontWeight: 300
        }
    },
    image: {
        width: (props) => (props.minimizeImage ? '300px' : '900px'),
        margin: (props) => (props.minimizeImage ? '10% 0 0 20%' : '0 0 0 40px'),
        display: 'block'
    },
    '@media screen and (max-width: 767px)': {
        // mobile
        infoSection: {
            backgroundImage: (props) => (props.allowFullScreenImageMobile ? `url(${props.mobileImage})` : 'none'),
            backgroundColor: (props) => (props.allowFullScreenImageMobile ? '' : '#fff'),
            flexDirection: 'column',
            padding: (props) => (props.allowFullScreenImageMobile ? '0' : '70px 40px'),
            minHeight: 'unset',
            '& > div:nth-of-type(1)': {
                padding: '0',
                margin: (props) => (props.allowFullScreenImageMobile ? '15px 0 0 10%' : 'unset'),
                width: (props) => (props.allowFullScreenImageMobile ? '80%' : 'unset')
            },
            '& > div:nth-of-type(2)': {
                overflow: 'hidden',
                margin: '0'
            }
        },
        infoSectionTitle: {
            color: (props) => (props.allowFullScreenImageMobile ? props.textColor : '#333333'),
            fontSize: '2em',
            fontWeight: 400
        },
        infoSectionContent: {
            color: (props) => (props.allowFullScreenImageMobile ? props.textColor : '#333333'),
            '& > div': {
                fontWeight: 300
            }
        },
        image: {
            width: (props) => (!props.allowFullScreenImageMobile && props.allowFullScreenImage ? '100vw' : '200px'),
            margin: (props) =>
                !props.allowFullScreenImageMobile && props.allowFullScreenImage ? '-70px 0 0 -40px' : '0 0 0 17%'
        },
        textContainer: {
            marginLeft: '-20px !important',
            marginTop: '20px !important'
        }
    },
    '@media only screen and (max-width: 321px)': {
        image: {
            margin: (props) =>
                !props.allowFullScreenImageMobile && props.allowFullScreenImage ? '-70px 0 0 -40px' : '0 0 0 10%'
        }
    }
};

class InfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // Gatsby build step with fail because window will be undefined, we need check first
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillMount() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    render() {
        const {
            classes,
            contentTitle,
            contentList,
            children,
            mobileImage,
            desktopImage,
            allowFullScreenImage,
            allowFullScreenImageMobile,
            imageAriaRole
        } = this.props;
        const { isMobile } = this.state;
        const contentArray = contentList.map((content, index) => {
            return content;
        });
        const image =
            allowFullScreenImage && allowFullScreenImageMobile ? (
                ''
            ) : (
                <div className={classes.imageContainer} role={imageAriaRole}>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={mobileImage} />
                        <img className={classes.image} src={desktopImage} alt="" />
                    </picture>
                </div>
            );

        return (
            <div className={classes.infoSection}>
                {isMobile && !allowFullScreenImageMobile && allowFullScreenImage && image}
                <div className={classes.textContainer}>
                    <div className={classes.infoSectionTitle}>{contentTitle}</div>
                    <div className={classes.infoSectionContent}>{contentArray}</div>
                    {children}
                </div>
                {!allowFullScreenImageMobile && !allowFullScreenImage && image}
            </div>
        );
    }
}

// Specifies the default values for props:
InfoSection.defaultProps = {
    showImageOnMobile: true,
    contentList: [],
    mobileImage: null,
    desktopImage: null,
    contentTitle: '',
    allowFullScreenImage: false,
    allowFullScreenImageMobile: false,
    textColor: '#333333',
    textAlign: 'left',
    minimizeImage: false,
    imageAriaRole: 'presentation'
};

InfoSection.propTypes = {
    /** Header/Title of the section */
    contentTitle: PropTypes.object.isRequired,
    /** Content for the section */
    contentList: PropTypes.array,
    /** Children to be rendered */
    children: PropTypes.object,
    /** Image to be rendered on right side of the section */
    desktopImage: PropTypes.string,
    /** Image to be rendered on top of the section when in Mobile mode */
    mobileImage: PropTypes.string,
    /** The image will take 100% of the screen width */
    allowFullScreenImage: PropTypes.bool,
    /** The image will take 100% of the screen width in mobile */
    allowFullScreenImageMobile: PropTypes.bool,
    /** The color of the text */
    textColor: PropTypes.string,
    /** The text alignment - left or right */
    textAlign: PropTypes.string,
    /** Minimize will shrink the image so that it doesn't take up half of the section's width */
    minimizeImage: PropTypes.bool,
    /** Add the Aria role of 'presentation' to such images */
    imageAriaRole: PropTypes.string
};

export default injectSheet(styles)(InfoSection);
