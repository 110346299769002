import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import themeColors from '../styles/theme';

const styles = {
    infoSectionTitle: {
        fontSize: '44px',
        lineHeight: '40px',
        fontStyle: 'normal',
        fontWeight: 300,
        marginBottom: '20px',
        color: (props) => props.textColor
    },
    infoSectionContent: {
        color: (props) => props.textColor,
        marginBottom: '30px',
        fontSize: '18px',
        fontWeight: 300,
        '& > div:nth-of-type(2)': {
            marginBottom: '30px',
            fontSize: '18px',
            fontWeight: 300
        }
    },
    image: {
        width: (props) => (props.minimizeImage ? '300px' : '900px'),
        margin: (props) => (props.minimizeImage ? '10% 0 0 20%' : '0 0 0 40px'),
        display: 'block'
    },
    imgContainer: {
        display: 'flex',
        backgroundSize: (props) => (props.backgroundSize ? props.backgroundSize : ''),
        backgroundPosition: (props) => props.backgroundPosition,
        backgroundRepeat: (props) => (props.backgroundRepeat ? props.backgroundRepeat : 'no-repeat'),
        backgroundImage: (props) => `url(${props.desktopImage})`,
        height: (props) => (props.imageHeight ? props.imageHeight : '')
    },
    infoSectionNew: {
        fontFamily: "'Open Sans','HelveticaNeue','HelveticaNeueu',Arial,sans-serif",
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        minHeight: '600px',
        background: (props) => props.backgroundColor,
        paddingBottom: (props) => (props.backgroundSize === 'cover' ? '' : '50px'),
        paddingTop: (props) => (props.backgroundSize === 'cover' ? '' : '50px'),
        borderBottom: (props) => (props.borderBottom ? '1px solid rgb(239, 239, 239)' : '')
    },
    contentContainer: {
        marginLeft: (props) => (props.textAlign === 'left' ? '22%' : '10%'),
        marginRight: '10%',
        marginTop: (props) => (props.contentMarginTop ? props.contentMarginTop : '30%')
    },
    '@media screen and (max-width: 1202px)': {
        infoSectionNew: {
            minHeight: '500px'
        }
    },
    '@media screen and (max-width: 950px)': {
        infoSectionNew: {
            minHeight: '400px'
        },
        contentContainer: {
            marginTop: '20% !important'
        }
    },
    '@media screen and (max-width: 767px)': {
        // mobile
        infoSectionNew: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '0px',
            paddingBottom: '0px !important',
            paddingTop: '0px !important'
        },
        contentContainer: {
            marginTop: '2% !important',
            marginLeft: '5% !important',
            marginBottom: '10% !important',
            marginRight: '5% !important'
        },
        imgContainer: {
            backgroundImage: (props) => `url(${props.mobileImage})`,
            minHeight: (props) => (props.backgroundSize === 'cover' ? '300px' : '388px'),
            height: 'unset !important'
        },
        infoSectionTitle: {
            color: themeColors.black,
            marginBottom: '2%',
            fontSize: '26px',
            fontWeight: 300
        },
        infoSectionContent: {
            color: themeColors.black,
            fontSize: '16px',
            fontWeight: 300,
            '& > div': {
                fontWeight: 300
            }
        },
        image: {
            width: (props) => (!props.allowFullScreenImageMobile && props.allowFullScreenImage ? '100vw' : '200px'),
            margin: (props) =>
                !props.allowFullScreenImageMobile && props.allowFullScreenImage ? '-70px 0 0 -40px' : '0 0 0 17%'
        }
    },
    '@media only screen and (max-width: 321px)': {
        image: {
            margin: (props) =>
                !props.allowFullScreenImageMobile && props.allowFullScreenImage ? '-70px 0 0 -40px' : '0 0 0 10%'
        }
    }
};

class TwoColumnInfoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // Gatsby build step with fail because window will be undefined, we need check first
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillMount() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    render() {
        const { classes, contentTitle, contentList, children, textAlign } = this.props;
        const { isMobile } = this.state;
        const contentArray = contentList.map((content) => {
            return content;
        });
        return (
            <div className={classes.infoSectionNew}>
                {isMobile && <div className={classes.imgContainer} />}
                {textAlign === 'left' && (
                    <div className="infoSection">
                        <div className={classes.contentContainer}>
                            <div className={classes.infoSectionTitle}>{contentTitle}</div>
                            <div className={classes.infoSectionContent}>{contentArray}</div>
                            {children}
                        </div>
                    </div>
                )}
                {!isMobile && <div className={classes.imgContainer} />}
                {textAlign === 'right' && (
                    <div className="infoSection">
                        <div className={classes.contentContainer}>
                            <div className={classes.infoSectionTitle}>{contentTitle}</div>
                            <div className={classes.infoSectionContent}>{contentArray}</div>
                            {children}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

// Specifies the default values for props:
TwoColumnInfoSection.defaultProps = {
    showImageOnMobile: true,
    contentList: [],
    mobileImage: null,
    desktopImage: null,
    contentTitle: '',
    allowFullScreenImage: false,
    allowFullScreenImageMobile: false,
    textColor: '#333333',
    textAlign: 'left',
    minimizeImage: false,
    imageAriaRole: 'presentation',
    backgroundSize: 'cover',
    backgroundRepeat: '',
    borderBottom: false,
    backgroundPosition: 'center'
};

TwoColumnInfoSection.propTypes = {
    /** Header/Title of the section */
    contentTitle: PropTypes.object.isRequired,
    /** Content for the section */
    contentList: PropTypes.array,
    /** Children to be rendered */
    children: PropTypes.object,
    /** Image to be rendered on right side of the section */
    desktopImage: PropTypes.string,
    /** Image to be rendered on top of the section when in Mobile mode */
    mobileImage: PropTypes.string,
    /** The image will take 100% of the screen width */
    allowFullScreenImage: PropTypes.bool,
    /** The image will take 100% of the screen width in mobile */
    allowFullScreenImageMobile: PropTypes.bool,
    /** The color of the text */
    textColor: PropTypes.string,
    /** The text alignment - left or right */
    textAlign: PropTypes.string,
    /** Minimize will shrink the image so that it doesn't take up half of the section's width */
    minimizeImage: PropTypes.bool,
    /** Add the Aria role of 'presentation' to such images */
    imageAriaRole: PropTypes.string,
    /** If the image should cover the display */
    backgroundSize: PropTypes.string,
    /** Image repeat */
    backgroundRepeat: PropTypes.string,
    /** show the border bottom */
    borderBottom: PropTypes.bool,
    /** background position of image */
    backgroundPosition: PropTypes.string
};

export default injectSheet(styles)(TwoColumnInfoSection);
