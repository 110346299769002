import React from 'react';
import injectSheet from 'react-jss';

const styles = {
    dropdown: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        position: 'relative',
        border: '1px solid #e4e4e4',
        borderRadius: '4px',
        boxShadow: 'none',
        fontSize: '14px',
        lineHeight: '21px',
        padding: '1px 8px',
        height: '2rem'
    },
    dropdownToggle: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        fontSize: '14px',
        lineHeight: '21px'
    },
    fullDropdownToggle: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '8px'
    },
    iconPiece: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '10px',
        lineHeight: '0'
    },
    dropdownMenu: {
        fontSize: '14px',
        background: 'white',
        borderRadius: '5px',
        padding: '0',
        margin: '2px 0 0',
        listStyle: 'none',
        position: 'absolute',
        top: '100%',
        left: '0',
        overflow: 'auto',
        transition: 'all 0.2s ease-out',
        maxHeight: '0',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 1px #c7c7c7'
    },
    dropdownItem: {
        position: 'relative',
        fontSize: '14px',
        lineHeight: '14px'
    },
    dataDiv: {
        boxSizing: 'content-box',
        display: 'flex',
        alignItems: 'center',
        padding: '4px 0px',
        whiteSpace: 'nowrap',
        color: '#000',
        textDecoration: 'none',
        '& span': {
            padding: '0 14px 0 0',
            whiteSpace: 'nowrap'
        }
    },
    checkMark: {
        display: 'inline-block',
        width: '14px',
        padding: '0 4px'
    },
    open: {
        maxHeight: '100vh',
        padding: '5px 0'
    },
    indent: {
        marginLeft: '23px'
    }
};

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '',
            open: false,
            currentItem: {
                value: '',
                text: ''
            }
        };

        this.itemSelected = this.itemSelected.bind(this);
        this.onItemChange = this.onItemChange.bind(this);
    }

    componentDidMount() {
        const { items, selectedIndex } = this.props;
        this.setState({
            currentItem: {
                value: items[selectedIndex].value,
                text: items[selectedIndex].text
            }
        });
    }

    itemSelected = (key) => {
        this.setState({
            selected: key
        });
        this.props.onChange(key);
    };

    toggleMenu = () => {
        this.setState({
            open: !this.state.open
        });
    };

    onItemChange = (e) => {
        let value = e.target.dataset.value;
        let children = e.target.innerText;
        if (!value) {
            return;
        }

        this.setState({
            currentItem: {
                value,
                text: children
            },
            open: false
        });

        this.props.onChange({ value });
    };

    render() {
        const { classes, items } = this.props;
        const { selected, open, currentItem } = this.state;

        return (
            <span>
                <span className={classes.dropdown}>
                    <span className={classes.fullDropdownToggle} onClick={this.toggleMenu}>
                        <span>{currentItem.text}</span>
                        <svg
                            viewBox="0 0 320 320"
                            className={classes.iconPiece}
                            height="10px"
                            width="10px"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M160 188.7 37.6 70 0 106.5 160 262l160-155.5L282.4 70 160 188.7z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </span>

                    <ul
                        zIndex={12}
                        className={`${classes.dropdownMenu} ${open ? classes.open : ''}`}
                        aria-hidden={!open}
                        tabIndex={open ? 0 : -1}
                        onClick={this.onItemChange}
                    >
                        {items.map((item) => {
                            return (
                                <li tabindex="-1" className={classes.dropdownItem}>
                                    <div data-value={item.value} className={classes.dataDiv}>
                                        {item.value === currentItem.value ? (
                                            <div className={classes.checkMark}>
                                                <svg
                                                    viewBox="0 0 320 320"
                                                    height="12px"
                                                    width="12px"
                                                    fill="currentColor"
                                                >
                                                    <path d="M4 186.9c-5.4-5.3-5.4-12.5 0-17.7L36.1 137c5.3-5.3 12.5-5.3 17.7 0l51.7 51.7c5.4 5.4 12.5 5.4 17.7 0L266.1 46.1c5.4-5.3 12.5-5.3 17.7 0L316 78.2c5.4 5.3 5.4 12.5 0 17.7l-172.9 173c-5.3 5.3-14.4 8.9-21.4 8.9h-14.4c-7 0-16.1-3.7-21.4-8.9L4 186.9z" />
                                                </svg>
                                            </div>
                                        ) : (
                                            <div className={classes.indent} />
                                        )}
                                        <span class="" data-value={item.value}>
                                            {item.text}
                                        </span>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </span>
            </span>
        );
    }
}

export default injectSheet(styles)(Dropdown);
