import React from 'react';
import injectSheet from 'react-jss';
import themeColors from '../../styles/theme';

import Translate from '../Translate';

import strengthPlansImage from '../../data/images/features/coach/strength/strength_plans.jpg';
import strengthPlansImageMobile from '../../data/images/features/coach/strength/strength_plans_mobile.jpg';
import preferencesCoachImage from '../../data/images/features/coach/strength/select_your_preferences.jpg';
import preferencesCoachImageMobile from '../../data/images/features/coach/strength/select_your_preferences_mobile.jpg';
import trackProgressImage from '../../data/images/features/coach/strength/track_your_progress.jpg';
import trackProgressImageeMobile from '../../data/images/features/coach/strength/track_your_progress_mobile.jpg';

import InfoSection from './infoSection';
import TwoColumnInfoSection from '../twoColumnInfoSection';
import { isChinaUser } from '../../config/isChina';

const styles = {
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    modal: {
        width: '800px',
        margin: '7% 0 0 15%',
        background: '#fff',
        '& iframe': {
            margin: '0 0 10px 20px'
        }
    },
    modalHeader: {
        padding: '7px 15px',
        color: '#222',
        overflow: 'hidden',
        lineHeight: '1',
        display: 'flex'
    },
    modalCloseButton: {
        color: '#888',
        cursor: 'pointer',
        border: '0',
        opacity: '1',
        padding: '0',
        fontSize: '15px',
        background: 'transparent',
        fontWeight: '400',
        lineHeight: '11px',
        margin: '7px 0 0 auto'
    },
    buttonContainr: {
        marginTop: '25px'
    }
};

class StrengthPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isChinaUser: false,
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            isChinaUser: isChinaUser()
        });
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // Gatsby build step with fail because window will be undefined, we need check first
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillMount() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.sectionContainer}>
                <InfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="strength_plans" />}
                    contentList={[<Translate ns="prelogin_pages" content="strength_plans_detail" />]}
                    desktopImage={strengthPlansImage}
                    mobileImage={strengthPlansImageMobile}
                    allowFullScreenImage={true}
                    textColor={themeColors.textColorWhite}
                />
                <TwoColumnInfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="select_preferences" />}
                    contentList={[<Translate ns="prelogin_pages" content="select_preferences_description" />]}
                    desktopImage={preferencesCoachImage}
                    mobileImage={preferencesCoachImageMobile}
                    backgroundColor={themeColors.white}
                    textColor={themeColors.textColor}
                    textAlign="right"
                    backgroundPosition="right"
                />
                <TwoColumnInfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="track_progress" />}
                    contentList={[<Translate ns="prelogin_pages" content="track_progress_description" />]}
                    desktopImage={trackProgressImage}
                    mobileImage={trackProgressImageeMobile}
                    backgroundColor={themeColors.white}
                    textColor={themeColors.textColor}
                    textAlign="left"
                    backgroundSize={'contain'}
                    backgroundRepeat={'no-repeat'}
                />
            </div>
        );
    }
}

export default injectSheet(styles)(StrengthPanel);
