import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
    title: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '22px'
    },
    description: {
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        textAlign: 'center'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        gap: '16px',
        width: '272px',
        height: '272px'
    }
};

class Card extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.card}>
                {this.props.children}
                <div className={classes.title}>{this.props.title}</div>
                <div className={classes.description}>{this.props.description}</div>
            </div>
        );
    }
}

export default injectSheet(styles)(Card);
