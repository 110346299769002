import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';

const styles = {
    infoSection: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '0',
        backgroundSize: 'cover',
        minHeight: '720px',
        backgroundPosition: (props) => props.imagePosition,
        backgroundImage: (props) => `url(${props.desktopImage})`,
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : '#fff'),
        borderBottom: (props) => (props.showBorder ? '1px solid #eeeeee' : '')
    },
    contentSection: {
        backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : '#fff'),
        textAlign: 'center',
        paddingTop: '30px',
        paddingBottom: '65px'
    },
    image: {
        width: (props) => (props.minimizeImage ? '300px' : '900px'),
        margin: (props) => (props.minimizeImage ? '10% 0 0 20%' : '0 0 0 40px'),
        display: 'block'
    },
    infoSectionHeader: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '44px',
        lineHeight: '41px',
        color: (props) => props.textColor
    },
    infoSectionSubHeader: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '32px',
        lineHeight: '34px',
        color: (props) => props.textColor
    },
    '@media screen and (max-width: 950px)': {
        infoSection: {
            minHeight: '500px'
        }
    },
    '@media screen and (max-width: 767px)': {
        // mobile
        infoSection: {
            backgroundImage: (props) => `url(${props.mobileImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: '',
            flexDirection: 'column',
            padding: '0',
            minHeight: '600px'
        },
        contentSection: {
            backgroundColor: (props) => (props.backgroundColor ? props.backgroundColor : '#fff'),
            textAlign: 'left',
            paddingTop: '15px',
            paddingLeft: '15px',
            paddingBottom: '15px'
        },
        infoSectionHeader: {
            fontSize: '2em',
            lineHeight: '21px'
        },
        infoSectionSubHeader: {
            fontSize: '1.625em'
        },
        image: {
            width: (props) => (!props.allowFullScreenImageMobile && props.allowFullScreenImage ? '100vw' : '200px'),
            margin: (props) =>
                !props.allowFullScreenImageMobile && props.allowFullScreenImage ? '-70px 0 0 -40px' : '0 0 0 17%'
        }
    },
    '@media only screen and (max-width: 700px)': {
        infoSection: {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            minHeight: '440px !important'
        }
    },
    '@media only screen and (max-width: 550px)': {
        contentSection: {
            paddingTop: '0px'
        },
        infoSection: {
            minHeight: '412px !important'
        }
    },
    '@media only screen and (max-width: 400px)': {
        image: {
            margin: (props) =>
                !props.allowFullScreenImageMobile && props.allowFullScreenImage ? '-70px 0 0 -40px' : '0 0 0 10%'
        },
        infoSection: {
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            minHeight: '330px !important'
        }
    }
};

class CoachHeroSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // Gatsby build step with fail because window will be undefined, we need check first
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillMount() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    render() {
        const {
            classes,
            children,
            mobileImage,
            desktopImage,
            allowFullScreenImage,
            allowFullScreenImageMobile,
            imageAriaRole,
            headerSubtitle,
            headerTitle
        } = this.props;
        const { isMobile } = this.state;
        const image =
            allowFullScreenImage && allowFullScreenImageMobile ? (
                ''
            ) : (
                <div className={classes.imageContainer} role={imageAriaRole}>
                    <picture>
                        <source media="(max-width: 767px)" srcSet={mobileImage} />
                        <img className={classes.image} src={desktopImage} alt="" />
                    </picture>
                </div>
            );

        return (
            <>
                <div className={classes.infoSection} />
                <div className={classes.contentSection}>
                    <div className={classes.infoSectionHeader}>{headerTitle}</div>
                    <div className={classes.infoSectionSubHeader}>{headerSubtitle}</div>
                    {children}
                </div>
            </>
        );
    }
}

// Specifies the default values for props:
CoachHeroSection.defaultProps = {
    showImageOnMobile: true,
    mobileImage: null,
    desktopImage: null,
    allowFullScreenImage: false,
    allowFullScreenImageMobile: false,
    textColor: '#333333',
    textAlign: 'left',
    minimizeImage: false,
    imageAriaRole: 'presentation',
    imagePosition: 'center',
    showBorder: true
};

CoachHeroSection.propTypes = {
    /** Children to be rendered */
    children: PropTypes.object,
    /** Image to be rendered on right side of the section */
    desktopImage: PropTypes.string,
    /** Image to be rendered on top of the section when in Mobile mode */
    mobileImage: PropTypes.string,
    /** The image will take 100% of the screen width */
    allowFullScreenImage: PropTypes.bool,
    /** The image will take 100% of the screen width in mobile */
    allowFullScreenImageMobile: PropTypes.bool,
    /** The color of the text */
    textColor: PropTypes.string,
    /** The text alignment - left or right */
    textAlign: PropTypes.string,
    /** Minimize will shrink the image so that it doesn't take up half of the section's width */
    minimizeImage: PropTypes.bool,
    /** Add the Aria role of 'presentation' to such images */
    imageAriaRole: PropTypes.string,
    /** image position */
    imagePosition: PropTypes.string,
    /** show the bottom border or not */
    showBorder: PropTypes.bool
};

export default injectSheet(styles)(CoachHeroSection);
