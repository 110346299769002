import React from 'react';
import injectSheet from 'react-jss';
import Modal from 'react-modal';
import Helmet from 'react-helmet';

import i18next from '../../config/i18next';
import Translate from '../../components/Translate';
import Layout from '../../components/layout';
import coachImage from '../../data/images/features/coach/hero_img.jpg';
import coachImageMobile from '../../data/images/features/coach/hero_img_mobile.jpg';
import selectSportImage from '../../data/images/features/coach/select_your_sport.jpg';
import selectSportImageMobile from '../../data/images/features/coach/select_your_sport_mobile.jpg';
import selectYourGoalsImage from '../../data/images/features/coach/your_goals_your_plan.jpg';
import selectYourGoalsImageMobile from '../../data/images/features/coach/your_goals_your_plan_mobile.jpg';
import powerAdaptiveTrainingImage from '../../data/images/features/coach/power_of_adaptive_training.jpg';
import powerAdaptiveTrainingImageMobile from '../../data/images/features/coach/power_of_adaptive_training_mobile.jpg';
import backedByScienceImage from '../../data/images/features/coach/backed_by_science.jpg';
import backedByScienceImageMobile from '../../data/images/features/coach/backed_by_science_mobile.jpg';
import howToGetStartedImage from '../../data/images/features/coach/get_started.jpg';
import howToGetStartedImageMobile from '../../data/images/features/coach/get_started_mobile.jpg';

import TwoColumnInfoSection from '../../components/twoColumnInfoSection';
import { isChinaUser } from '../../config/isChina';
import themeColors from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/button';
import Card from '../../components/card';
import CoachHeroSection from '../../components/coach/coachHeroSection';
import Tabs from '../../components/tabs';
import Dropdown from '../../components/dropdown';
import RunningPanel from '../../components/coach/runningPanel';
import CyclingPanel from '../../components/coach/cyclingPanel';
import StrengthPanel from '../../components/coach/strengthPanel';

const styles = {
    landingPage: {
        background: themeColors.white
    },
    section: {
        background: '#000',
        padding: '30px 10% 30px 10%',
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        height: '80vh',
        minHeight: '800px',
        '& h2': {
            fontSize: '2.7em',
            textAlign: 'center',
            lineHeight: '40px',
            fontWeight: '500',
            margin: '35px 0 50px 0'
        },
        '& h3': {
            fontSize: '1.9em',
            lineHeight: '30px',
            fontWeight: '500'
        },
        '& p': {
            fontWeight: 400,
            fontSize: '1.2em',
            width: '70%'
        }
    },
    instructions: {
        flexBasis: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        '& p': {
            width: '80%',
            paddingLeft: '10%'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    buttonLight: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'block',
        width: '80%',
        margin: '30px 0 0 10%',
        textAlign: 'center',
        '& span': {
            width: '100px',
            border: '1px solid #fff',
            padding: '10px 15px 10px 15px'
        }
    },
    descriptionSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: themeColors.black
    },
    descriptionContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        background: themeColors.black,
        color: colors.lightui_surface_2,
        paddingTop: '20px',
        paddingBottom: '20px',
        maxWidth: '1000px'
    },
    descriptionTitle: {
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '32px',
        paddingRight: '20px',
        paddingLeft: '20px',
        lineHeight: '32px'
    },
    cardContainer: {
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    sportSpecificContainer: {
        color: themeColors.textColor,
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '32px',
        lineHeight: '34px',
        paddingTop: '50px',
        marginLeft: '11%'
    },
    plansDropdown: {
        fontSize: '14px',
        height: 'unset',
        padding: '8px',
        width: '92vw',
        border: '1.5px solid #e4e4e4',
        boxShadow: 'none'
    },
    dropdownContainer: {
        marginLeft: '16px',
        paddingTop: '15px',
        paddingBottom: '15px',
        marginRight: '15px'
    },
    featureSection: {
        width: '100%',
        padding: '50px 0px 20px',
        backgroundColor: colors.lightiu_surface_3,
        textAlign: 'center',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '14px',
        lineHeight: '22px',
        '& a': {
            color: colors.blue_dark_1
        }
    },
    link: {
        '& a': {
            color: colors.darkui_surface_1,
            textDecoration: 'underline'
        }
    },
    '@media only screen and (max-width: 767px)': {
        instructions: {
            flexDirection: 'column'
        },
        column: {
            marginBottom: '65px'
        },
        section: {
            height: '100%',
            '& h2': {
                fontSize: '2em'
            },
            '& h3': {
                fontSize: '1.7em'
            },
            '& p': {
                fontSize: '1em'
            }
        },
        cardContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 'unset'
        },
        descriptionTitle: {
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '18px',
            lineHeight: '25px'
        },
        sportSpecificContainer: {
            fontSize: '22px',
            lineHeight: '34px',
            marginLeft: '4%'
        }
    }
};

Modal.setAppElement(`#___gatsby`);

class CoachPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            isMobile: false,
            isChinaUser: false,
            activeTab: 'running'
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));

        this.setState({ isChinaUser: isChinaUser() });
    }

    resize() {
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    getSelectedIndex() {
        const { activeTab } = this.state;
        switch (activeTab) {
            case 'running':
                return 0;
            case 'cycling':
                return 1;
            case 'strength':
                return 2;
            default:
                return 0;
        }
    }

    getFeatureLink() {
        const { activeTab } = this.state;

        switch (activeTab) {
            case 'running':
                return `https://support.garmin.com/sas/connect/?contentId=xmMRe8rjaZ3CNaINXf8dLA`;
            case 'cycling':
                return `https://support.garmin.com/sas/connect/?contentId=9WEulyuZyf6aDpcxPH1PI9`;
            case 'strength':
                return `https://support.garmin.com/sas/connect/?contentId=pVtmVTZz7C97GZHxtMWgs8`;
            default:
                return `https://support.garmin.com/sas/connect/?contentId=xmMRe8rjaZ3CNaINXf8dLA`;
        }
    }

    gettingStartedClick(evt) {
        window.open('https://connect.garmin.com/garminCoach', '_blank');
    }

    render() {
        const { classes } = this.props;
        const { isMobile } = this.state;

        return (
            <Layout>
                <div className={classes.landingPage}>
                    <Helmet>
                        <title>{'Garmin Coach Plan'}</title>
                        <meta name="title" content={'Garmin Coach Plan'} />
                        <meta property="og:title" content={'Garmin Coach Plan'} />
                        <meta property="og:url" content={`https://connect.garmin.com/features/coach`} />
                        <meta
                            property="og:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                        <meta name="twitter:title" content={'Garmin Coach Plan'} />
                    </Helmet>
                    <CoachHeroSection
                        headerTitle={<Translate ns="prelogin_pages" content="coach_title" />}
                        headerSubtitle={<Translate ns="prelogin_pages" content="training_for_everyone" />}
                        desktopImage={coachImage}
                        mobileImage={coachImageMobile}
                        textAlign="left"
                        textColor={themeColors.textColorWhite}
                        backgroundColor={themeColors.black}
                        imagePosition={'center'}
                        showBorder={false}
                    />

                    <div className={classes.descriptionSection}>
                        <div className={classes.descriptionContainer}>
                            <div className={classes.descriptionTitle}>
                                <Translate ns="prelogin_pages" content="coach_plans_overview" />
                            </div>
                            <div className={classes.cardContainer}>
                                <Card
                                    title={<Translate ns="prelogin_pages" content="train_for_event_title" />}
                                    description={
                                        <Translate ns="prelogin_pages" content="train_for_event_description" />
                                    }
                                >
                                    <svg
                                        width="128"
                                        height="129"
                                        viewBox="0 0 128 129"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.25" width="128" height="128" rx="64" fill="#252525" />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M90.5269 59.2677H85.2075C77.5085 57.3079 72.4691 48.2091 63.2303 45.5494L59.5907 44.8495H55.9512C55.9224 42.7619 52.946 42.2107 52.1716 44.1496L37.3335 78.4453L40.973 79.9851L45.8724 68.5065H51.3317L53.0115 68.9265C55.5174 69.6434 57.8839 70.7793 60.0107 72.2861C62.2504 73.8259 64.2101 75.6456 66.1699 77.1855C69.9494 80.4051 73.589 83.2047 78.3484 83.2047H80.5881L90.6668 59.4077L90.5269 59.2677ZM81.9879 75.2257C79.9557 75.2809 77.9398 74.8489 76.1087 73.9659L73.589 79.9851C71.2604 78.7725 69.0965 77.2672 67.1498 75.5057L69.6695 69.4864C67.7062 67.7434 65.6502 66.1079 63.5102 64.587L60.8506 70.6063C58.7493 69.1425 56.436 68.0095 53.9914 67.2467L56.5111 61.2274C54.3716 60.6334 52.1486 60.3969 49.9319 60.5275L53.2915 52.5485C55.5081 52.4179 57.7311 52.6544 59.8707 53.2484L62.8103 46.2493C65.2702 46.9733 67.5895 48.1093 69.6695 49.6089L66.7298 56.608C68.9695 58.1478 70.9293 59.9676 72.8891 61.5074L75.8287 54.6483C77.7758 56.3705 79.9407 57.8295 82.2679 58.9877L79.3283 65.9869C77.0036 64.7679 74.8404 63.2631 72.8891 61.5074L69.5295 69.3464C71.4835 71.0987 73.6463 72.6032 75.9687 73.8259L79.3283 65.9869C81.1576 66.8752 83.1748 67.3074 85.2075 67.2467L81.9879 75.2257ZM63.3702 64.4471L66.7298 56.608C64.6418 55.1219 62.3249 53.9871 59.8707 53.2484L56.5111 61.0875C58.9671 61.8213 61.2848 62.9565 63.3702 64.4471Z"
                                            fill="#F4F4F4"
                                        />
                                    </svg>
                                </Card>
                                <Card
                                    title={<Translate ns="prelogin_pages" content="achieve_milestone_title" />}
                                    description={
                                        <Translate ns="prelogin_pages" content="achieve_milestone_description" />
                                    }
                                >
                                    <svg
                                        width="128"
                                        height="129"
                                        viewBox="0 0 128 129"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.25" width="128" height="128" rx="64" fill="#252525" />
                                        <path
                                            d="M59.601 53.5403C59.6067 53.4622 59.6067 53.3837 59.601 53.3056L53.7413 40.9539H62.2606L68.0771 53.4336C66.7679 53.1678 65.4351 53.032 64.0985 53.0283C62.5848 53.0344 61.0764 53.2061 59.601 53.5403ZM74.2827 40.9539L70.1528 49.7643L66.0445 40.9539H74.2827ZM71.6664 54.6496L79.6235 37.5833H48.4005L56.336 54.6496C52.2677 56.3741 48.9305 59.4356 46.8919 63.3137C44.8533 67.1917 44.2393 71.6466 45.1541 75.9209C46.069 80.1952 48.4564 84.0249 51.9101 86.7586C55.3639 89.4922 59.6709 90.9612 64.0985 90.9156C68.5092 90.9163 72.7851 89.4154 76.2042 86.6663C79.6233 83.9172 81.9762 80.0883 82.8657 75.826C83.7551 71.5638 83.1266 67.1293 81.0862 63.2712C79.0459 59.4132 75.7187 56.3679 71.6664 54.6496Z"
                                            fill="#F4F4F4"
                                        />
                                    </svg>
                                </Card>
                                <Card
                                    title={<Translate ns="prelogin_pages" content="improve_fitness_title" />}
                                    description={
                                        <Translate ns="prelogin_pages" content="improve_fitness_description" />
                                    }
                                >
                                    <svg
                                        width="128"
                                        height="129"
                                        viewBox="0 0 128 129"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect y="0.25" width="128" height="128" rx="64" fill="#252525" />
                                        <path
                                            d="M90.6663 61.1987V48.4153H77.8829L82.5134 53.0623L67.5304 68.0453L56.6818 57.2132L37.333 76.5786L40.839 80.0845L56.6818 64.2416L67.5304 75.0737L86.0193 56.5683L90.6663 61.1987Z"
                                            fill="#F4F4F4"
                                        />
                                    </svg>
                                </Card>
                                <Card
                                    title={<Translate ns="prelogin_pages" content="gain_strength_title" />}
                                    description={<Translate ns="prelogin_pages" content="gain_strength_description" />}
                                >
                                    <svg
                                        width="128"
                                        height="128"
                                        viewBox="0 0 128 128"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect width="128" height="128" rx="64" fill="#252525" />
                                        <path
                                            d="M89.3332 66.6666H86.6665V63.9999C86.6665 63.2927 86.0404 62.6666 85.3332 62.6666H82.6665C81.9593 62.6666 81.3332 63.2927 81.3332 63.9999V66.6666H78.0932L68.6665 50.6666H59.3332L49.9998 66.6666H46.6665V63.9999C46.6665 63.2927 46.0404 62.6666 45.3332 62.6666H42.6665C41.9593 62.6666 41.3332 63.2927 41.3332 63.9999V66.6666H38.6665V69.3333H41.3332V71.9999C41.3332 72.7072 41.9593 73.3333 42.6665 73.3333H45.3332C46.0404 73.3333 46.6665 72.7072 46.6665 71.9999V69.3333H57.4265L54.6665 89.3333L55.9998 90.6666L57.3332 89.3333L62.3998 69.3333H65.4132L70.7465 89.3333L72.0798 90.6666L73.4132 89.3333L70.5732 69.3333H81.3332V71.9999C81.3332 72.7072 81.9593 73.3333 82.6665 73.3333H85.3332C86.0404 73.3333 86.6665 72.7072 86.6665 71.9999V69.3333H89.3332V66.6666ZM57.7732 66.6666H52.8798C53.2798 66.0533 57.9598 58.3066 58.6665 58.7466C58.6665 61.4095 58.1455 64.0324 57.7732 66.6666ZM70.1998 66.6666C69.8653 64.1499 69.3332 61.5948 69.3332 59.0533C69.3332 58.7999 69.4532 58.6266 69.8132 58.9333C71.2132 60.1599 74.8665 66.3066 75.1465 66.6666H70.1998ZM63.9998 47.9999C65.0547 47.9999 66.0858 47.6871 66.9629 47.1011C68.717 45.929 69.6423 43.6952 69.2307 41.6261C68.8191 39.557 67.1094 37.8473 65.0403 37.4357C64.0058 37.2299 62.9334 37.3356 61.9589 37.7392C60.0098 38.5466 58.6665 40.5569 58.6665 42.6666C58.6665 45.5715 61.0949 47.9999 63.9998 47.9999Z"
                                            fill="#F4F4F4"
                                        />
                                    </svg>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <TwoColumnInfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="select_sport" />}
                        contentList={[<Translate ns="prelogin_pages" content="select_sport_description" />]}
                        desktopImage={selectSportImage}
                        mobileImage={selectSportImageMobile}
                        backgroundColor={themeColors.white}
                        textColor={themeColors.textColor}
                        textAlign="left"
                        backgroundPosition="left"
                        key={Math.random()}
                    />

                    <TwoColumnInfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="select_goals" />}
                        contentList={[<Translate ns="prelogin_pages" content="select_goals_description" />]}
                        desktopImage={selectYourGoalsImage}
                        mobileImage={selectYourGoalsImageMobile}
                        backgroundColor={themeColors.white}
                        textColor={themeColors.textColor}
                        textAlign="right"
                        backgroundPosition="right"
                        borderBottom={true}
                    />

                    <TwoColumnInfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="power_of_adaptive" />}
                        contentList={[<Translate ns="prelogin_pages" content="power_of_adaptive_description" />]}
                        desktopImage={powerAdaptiveTrainingImage}
                        mobileImage={powerAdaptiveTrainingImageMobile}
                        backgroundColor={themeColors.white}
                        textColor={themeColors.textColor}
                        textAlign="right"
                        backgroundSize={'contain'}
                        backgroundRepeat={'no-repeat'}
                        borderBottom={true}
                    />

                    <TwoColumnInfoSection
                        key={Math.random()}
                        contentTitle={<Translate ns="prelogin_pages" content="backed_by_science" />}
                        contentList={[
                            <span className={classes.link}>
                                <Translate
                                    ns="prelogin_pages"
                                    content="backed_by_science_description"
                                    tag="div"
                                    params={{
                                        0: `https://www.garmin.com/${i18next.language}/garmin-technology/garmin-coach/garmin-run-coach/?cdncache=false`,
                                        1: `https://www.garmin.com/${i18next.language}/garmin-technology/garmin-coach/garmin-cycling-coach/?cdncache=false`
                                    }}
                                />
                            </span>
                        ]}
                        desktopImage={backedByScienceImage}
                        mobileImage={backedByScienceImageMobile}
                        backgroundColor={themeColors.white}
                        textColor={themeColors.textColor}
                        textAlign="right"
                        contentMarginTop="21%"
                        backgroundPosition="right"
                    />

                    <TwoColumnInfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="how_to_get_started_title" />}
                        contentList={[
                            <Translate ns="prelogin_pages" content="getting_started_description" />,
                            <div className={classes.paddingTop}>
                                <Button size="large" onClick={this.gettingStartedClick}>
                                    <Translate ns="prelogin_pages" content="find_plan_label" />
                                </Button>
                            </div>
                        ]}
                        desktopImage={howToGetStartedImage}
                        mobileImage={howToGetStartedImageMobile}
                        backgroundColor={themeColors.white}
                        textColor={themeColors.textColor}
                        textAlign="left"
                        borderBottom={true}
                        backgroundPosition="left"
                        key={Math.random()}
                    />

                    <div className={classes.sportSpecificContainer}>
                        <Translate ns="prelogin_pages" content="sport_specific_plans" />
                    </div>
                    {isMobile ? (
                        <div>
                            <div className={classes.dropdownContainer}>
                                <Dropdown
                                    onChange={(val) => {
                                        this.setState({
                                            activeTab: val.value
                                        });
                                    }}
                                    selectedIndex={this.getSelectedIndex()}
                                    items={[
                                        {
                                            value: 'running',
                                            text: <Translate ns="prelogin_pages" content="running" />
                                        },
                                        {
                                            value: 'cycling',
                                            text: <Translate ns="prelogin_pages" content="cycling" />
                                        },
                                        {
                                            value: 'strength',
                                            text: <Translate ns="prelogin_pages" content="strength" />
                                        }
                                    ]}
                                />
                            </div>
                            <div>{this.state.activeTab === 'running' && <RunningPanel />}</div>
                            <div>{this.state.activeTab === 'cycling' && <CyclingPanel />}</div>
                            <div>{this.state.activeTab === 'strength' && <StrengthPanel />}</div>
                        </div>
                    ) : (
                        <Tabs
                            marginLeft={'11%'}
                            defaultTab={this.getSelectedIndex()}
                            tabs={[
                                {
                                    title: <Translate ns="prelogin_pages" content="running" />,
                                    content: <RunningPanel />,
                                    onClick: () => {
                                        this.setState({
                                            activeTab: 'running'
                                        });
                                    }
                                },
                                {
                                    title: <Translate ns="prelogin_pages" content="cycling" />,
                                    content: <CyclingPanel />,
                                    onClick: () => {
                                        this.setState({
                                            activeTab: 'cycling'
                                        });
                                    }
                                },
                                {
                                    title: <Translate ns="prelogin_pages" content="strength" />,
                                    content: <StrengthPanel />,
                                    onClick: () => {
                                        this.setState({
                                            activeTab: 'strength'
                                        });
                                    }
                                }
                            ]}
                        />
                    )}

                    <div className={classes.featureSection}>
                        <Translate
                            key={this.getFeatureLink()}
                            ns="prelogin_pages"
                            tag="div"
                            content="available_features"
                            params={{
                                0: this.getFeatureLink()
                            }}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(CoachPage);
