import React from 'react';
import injectSheet from 'react-jss';
import themeColors from '../styles/theme';
import colors from '../styles/colors';

const styles = {
    tabsContainer: {
        background: themeColors.white
    },
    navContainer: {
        marginLeft: (props) => props.marginLeft || '0px'
    },
    nav: {
        marginBottom: '20px',
        marginLeft: '0',
        listStyle: 'none'
    },
    navTabs: {
        display: 'flex',
        alignItems: 'flex-start',
        borderBottom: `1px solid ${colors.lightui_accent_2}`,
        marginRight: '25%'
    },
    tabTitle: {
        display: 'block',
        margin: '0',
        padding: '7.5px 10px',
        borderRadius: '0',
        border: 'none',
        borderBottom: '2px solid transparent',
        backgroundColor: 'transparent',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '22px',
        lineHeight: '25px'
    },
    active: {
        color: colors.darkui_surface_1,
        borderLeft: 'none',
        borderRight: 'none',
        borderBottom: '2px solid #1265C2',
        cursor: 'default'
    },
    nonActive: {
        color: colors.darkui_accent_2,
        backgroundColor: 'transparent',
        cursor: 'pointer'
    },
    disabled: {
        pointerEvents: 'none',
        opacity: '0.5'
    }
};

class Tabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            selectedTab:
                props.defaultTab !== null ? props.defaultTab : props.currentTab !== null ? props.currentTab[0] : 0,
            tabsLength: props.tabs.length
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.resize.bind(this));
    }

    resize() {
        // Gatsby build step with fail because window will be undefined, we need check first
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillMount() {
        this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    onTabClicked = (index) => {
        const { tabs, disabled } = this.props;

        if (disabled) {
            return;
        }

        this.setState({
            selectedTab: index
        });

        if (tabs[index].onClick) {
            tabs[index].onClick(index);
        }
    };

    handleKeyDown = (e) => {
        const { disabled } = this.props;
        const { selectedTab, tabsLength } = this.state;

        if (disabled) {
            return;
        }

        if (e.key === 'ArrowLeft' && selectedTab > 0) {
            e.preventDefault();
            this.setState({
                selectedTab: selectedTab - 1
            });
        } else if (e.key === 'ArrowRight' && selectedTab < tabsLength.current - 1) {
            e.preventDefault();
            this.setState({
                selectedTab: selectedTab + 1
            });
        }
    };

    buildClassName = (tab, index) => {
        const { classes, disabled } = this.props;
        const { selectedTab } = this.state;

        let className = [];
        if (tab.className) {
            className.push(classes[tab.className]);
        }

        if (selectedTab === index) {
            className.push(classes.active);
        } else {
            className.push(classes.nonActive);
        }

        if (disabled) {
            className.push(classes.disabled);
        }

        return className.join(' ');
    };

    renderTab = (tab, index) => {
        const { classes, disabled } = this.props;
        const { selectedTab } = this.state;

        return (
            <li
                className={this.buildClassName(tab, index)}
                key={index}
                onClick={() => this.onTabClicked(index)}
                role="tab"
                aria-disabled={disabled}
                aria-selected={selectedTab === index}
            >
                <div className={classes.tabTitle}>{tab.title}</div>
            </li>
        );
    };

    buildTabButtons = () => {
        const { tabs } = this.props;
        const { tabsLength } = this.state;

        let tabButtons = [];

        for (let i = 0, length = tabsLength; i < length; i++) {
            tabButtons.push(this.renderTab(tabs[i], i));
        }

        return tabButtons;
    };

    render() {
        const { classes, className, tabs } = this.props;
        const { selectedTab } = this.state;

        return (
            <div className={classes.tabsContainer}>
                <div className={classes.navContainer}>
                    <ul
                        className={`${classes.nav} ${classes.navTabs} ${className ? classes[className] : ''}`}
                        tabIndex="0"
                        onKeyDown={this.handleKeyDown}
                        role="tablist"
                    >
                        {this.buildTabButtons()}
                    </ul>
                </div>
                {tabs[selectedTab]?.content}
            </div>
        );
    }
}

export default injectSheet(styles)(Tabs);
